<template>
  <!-- CLIENTS LOGOS SLIDER-->
  <v-sheet class="py-16 " :color="wsBACKGROUND"  >

    <div>
      <v-row class="mx-0 justify-center" style="max-width: 1140px; margin-left: auto !important; margin-right: auto !important">
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <div v-if="!SM">
            <h3 :class="[{'text-center' : SM}]">
              {{ $t('homepage_westudy.sections.payment_logos.title') }}
            </h3>
            <h5 :class="[{'text-center' : SM}]" class="mt-3">
              {{ $t('homepage_westudy.sections.payment_logos.text') }}
            </h5>
          </div>
          <div v-else>
            <h1 :style="`font-size : ${SM ? '18px' : ''}`"
                :class="[{'text-center' : SM}]"
            >
              {{ $t('homepage_westudy.sections.payment_logos.title') }}
            </h1>
            <h4 :class="[{'text-center' : SM}]"
                class="font-weight-medium mt-3"
                style="color: #7a7a7a " >
              {{ $t('homepage_westudy.sections.payment_logos.text') }}
            </h4>
          </div>

        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xl="8">
          <div class="d-flex align-center fill-height">
            <div class="d-flex   justify-center align-center fill-height" style="width: 100%" >
              <div style="outline: none;" class="swiper-button-next" slot="button-next">
                <v-btn :color="wsACCENT" icon large>
                  <v-icon large>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <swiper class="swiper d-flex justify-center" style="width: 100%" key="swiper" id="swiper_clients"  :options="swiperOption">

                <swiper-slide   v-for="(item,i) in clientLogos" :key="i">

                  <div class="d-flex justify-center">
                    <img   class="text-center "
                           style="padding-top: 5px"
                           width="180"
                           alt="WeStudy"
                           :src="require(`@/assets/payment_systems/${item}_logo.png`)"
                    />
                  </div>


                </swiper-slide>

                <div class="swiper-pagination d-flex justify-center" slot="pagination">
                </div>
              </swiper>
              <div style=" outline: none;" class="swiper-button-prev" slot="button-prev">
                <v-btn :color="wsACCENT" icon large>
                  <v-icon large>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>


  </v-sheet>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper";

export default {
  name: "homeSectionPaymentLogos",
  components : {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      clientLogos : [ 'wayforpay','monobank','fondy','liqpay',],
      swiperOption : {
        loop: false,
        slidesPerView : 3,
        spaceBetween : 16,
        navigation : {
          nextEl : '.swiper-button-prev',
          prevEl : '.swiper-button-next',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          }
        },
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          loop: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable : true,
          // renderBullet: (idx, className) =>
          //     `<v-btn small href="#" class="noCaps ${className}">
          //     ${idx}
          //     </v-btn>`
        }
      },
    }
  }
}
</script>

<style scoped>

</style>